/* Add this CSS to App.css or a dedicated CSS file */

.navbar {
    width: 100%;
    background-color: #f0f0f0;
    /* Light grey background, change as needed */
    position: fixed;
    /* Fix navbar to the top of the screen */
    top: 0;
    left: 0;
    padding: 0.625rem 0;
    /* 10px equivalent */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: adds a slight shadow for depth */
}

.nav-items {
    display: flex;
    justify-content: flex-end;
    /* Align items to the right */
    padding-right: 1.25rem;
    /* 20px equivalent */
}

.nav-item {
    margin: 0 0.625rem;
    /* 10px equivalent */
    font-size: 1.2em;
    /* Make text larger */
    font-weight: bold;
    /* Make text bold */
    text-decoration: none;
    /* Remove underline from links */
    color: #333;
    /* Dark text color, change as needed */
}

.nav-item:hover {
    color: #007bff;
    /* Change color on hover, adjust as needed */
}
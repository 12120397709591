/* Container styling with vertical centering */
.container {
    display: flex;
    align-items: center;
    /* Centers items horizontally in a flex column container */
    justify-content: center;
    /* Centers items vertically */
    flex-direction: column;
    min-height: 60vh;
    /* Minimum height to maintain a decent vertical size, allows for expansion */
    max-width: 37.5rem;
    /* 600px equivalent, confines width but allows centering via auto margins */
    margin: auto;
    /* Centers the container horizontally on the page */
    margin-top: 5rem;
    /* Adds top margin */
    padding: 1.25rem;
    /* Padding around the contents */
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* Input and textarea styling */
input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 0.75rem;
    /* 12px equivalent */
    margin-top: 0.625rem;
    /* 10px equivalent */
    margin-bottom: 0.625rem;
    /* 10px equivalent */
    border: 1px solid #ccc;
    border-radius: 0.375rem;
    /* 6px equivalent */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;


}

/* Button styling adjustments */
.--btn {
    padding: 0.75rem 1.5rem;
    /* 12px 24px equivalent */
    border-radius: 0.3125rem;
    /* 5px equivalent */
    margin-top: 0.9375rem;
    /* 15px equivalent */
}

.--btn-primary {
    background-color: #007bff;
    color: white;
}

.--btn-primary:hover {
    background-color: #0069d9;
    cursor: pointer;
}

/* Form control and card styling */
.--form-control {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    /* 10px equivalent, maintains spacing between form elements */
    width: 60%;
    /* Ensures form controls use the full width of the container */
    align-items: center;
    /* Centers the form elements horizontally */
}

.--card {
    padding: 1.5625rem;
    /* 25px equivalent */
    background: #fff;
    border-radius: 0.625rem;
    /* 10px equivalent */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 1.5625rem;
    text-align: center;
    /* Centers the heading text */
    width: 100%;
    /* Ensures the heading takes full width for consistent centering */
    /* 25px equivalent */
}

.feedback-message {
    margin-top: 1.25rem;
    /* 20px equivalent */
    font-size: 1rem;
    /* 16px equivalent */
    color: green;
    /* Positive feedback in green */
    text-align: center;
}

.feedback-message.error {
    color: red;
    /* Error messages in red */
}
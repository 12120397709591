.production-page {
    font-family: Arial, sans-serif;
    margin: 1.25rem;
    /* 20px equivalent */
    padding: 1.25rem;
    /* 20px equivalent */
    background-color: #f9f9f9;
    border-radius: 0.625rem;
    /* 10px equivalent */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.production-page h1 {
    text-align: center;
    color: #333;
}

ul {
    list-style-type: none;
    /* Remove bullet points for all lists */
    padding-left: 0;
    /* Remove padding to align content to the left */
}

.step-item {
    margin-bottom: 1.25rem;
    /* 20px equivalent */
    padding: 0.625rem;
    /* 10px equivalent */
    background-color: #fff;
    border-left: 0.3125rem solid #007bff;
    /* 5px equivalent */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.step-item h3 {
    color: #007bff;
}

.details-list {
    margin-top: 0.625rem;
    /* 10px equivalent */
    color: #666;
}
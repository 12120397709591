/* src/Home.css */
.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    text-align: center;
    padding: 1.25rem;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.25rem;
}

.left-section,
.right-section {
    margin: 1.25rem;
}

h1 {
    font-size: 2rem;
    font-weight: bold;
}

.icons,
.tech-stack {
    display: flex;
    justify-content: center;
    gap: 0.625rem;
}

.icon-placeholder,
.tech-icon-placeholder {
    width: 3.75rem;
    height: 3.75rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-placeholder {
    width: 9.375rem;
    height: 9.375rem;
    border: 2px solid black;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}

.tech-stack {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin-top: 1.25rem;
    padding: 0.625rem;
    background-color: #f2f2f2;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
}

.tech-stack-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tech-stack-label {
    font-weight: bold;
    margin-right: 0.625rem;
}

.main-content {
    background-color: white;
    padding: 1.25rem;
    margin: 1.25rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem;
}
.projects-page-container {
    display: flex;
    flex-wrap: wrap;
    /* Allows cards to wrap to the next line */
    gap: 1.25rem;
    /* 20px equivalent */
    justify-content: center;
    /* Center cards horizontally */
    padding: 1.25rem;
    /* 20px equivalent */
}

.project-image-wrapper {
    flex: 1;
    /* Take up equal space as the description */
}

body {
    padding-top: 2.5rem;
    /* 40px equivalent, Adds padding to the top of the card */
}

.project-image {
    width: 100%;
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Cover the space without distorting the image */
}

.project-description-wrapper {
    flex: 1;
    /* Take up equal space as the image */
    padding: 1.25rem;
    /* 20px equivalent */
}

.project-title {
    margin: 0 0 0.625rem 0;
    /* 10px equivalent, Space between title and description */
    color: #333;
    /* Title text color */
}

.project-description {
    margin: 0;
    /* No extra margin */
    color: #666;
    /* Description text color */
}
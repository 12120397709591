.project-card-container {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    /* 8px equivalent */
    overflow: hidden;
    margin-bottom: 1.25rem;
    /* 20px equivalent */
    background-color: white;
    width: 90%;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hide {
    display: none;
    /* This will hide the image */
}

.project-card-container:hover {
    transform: translateY(-0.3125rem);
    /* 5px equivalent */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.project-image-wrapper {
    position: relative;
    border-right: 2px solid #f0f0f0;
    max-width: 12.5rem;
    /* 200px equivalent */
    overflow: hidden;
}

.background-square {
    position: absolute;
    bottom: 0.625rem;
    /* 10px equivalent */
    left: 0.625rem;
    /* 10px equivalent */
    width: 5rem;
    /* 80px equivalent */
    height: 5rem;
    /* 80px equivalent */
    background-color: lightblue;
    z-index: 0;
}

.project-image {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transition: transform 0.3s ease;
}

.project-image:hover {
    transform: scale(1.05);
}

.project-description-wrapper {
    flex: 1;
    padding: 1.25rem;
    /* 20px equivalent */
}

.project-title {
    margin: 0 0 0.625rem 0;
    /* 10px equivalent */
    color: #333;
    font-size: 1.5rem;
    /* 24px equivalent */
    font-weight: bold;
}

.project-description {
    margin: 0;
    color: #666;
    font-size: 1rem;
    /* 16px equivalent */
    line-height: 1.5;
}

.expand-button {
    padding: 0.5rem 1rem;
    /* 8px 16px equivalent */
    margin-top: 0.625rem;
    /* 10px equivalent */
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 0.25rem;
    /* 4px equivalent */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.expand-button:hover {
    background-color: #0056b3;
}

.additional-images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* or 0.625rem; based on your preference */
    overflow: auto;
    /* ensures scrollbars if content overflows */
    align-items: center;
    justify-content: center;
    padding: 15px;
    /* or 0.9375rem; */
    border-top: 1px solid #eee;
    min-height: 200px;
    /* Adjust based on your content */
}

/* Base styles for .extra-project-image */
.extra-project-image {
    max-width: 100%;
    /* ensures image is not wider than its container */
    height: auto;
    /* maintains aspect ratio */
    object-fit: cover;
    /* covers the area of the box without distorting the aspect ratio */
    border-radius: 4px;
    /* or 0.25rem; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
/* src/About.css */
.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    text-align: center;
    gap: 1.25rem;
}

.box {
    flex: 1;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-box {
    background-size: cover;
    background-position: center;
    border: 1px solid #ccc;
}

.info-box {
    background-color: #f0f0f0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2,
p {
    margin: 0.625rem 0;
}

.about-image {
    max-width: 50%;
    height: auto;
    border-radius: 0.3125rem;
}